/* The following line can be included in a src/App.scss */

@import "custom";

@import "~bootstrap/scss/variables";

body {
  background-image: url(/assets/waves.svg);
  background-size: cover;
}
// input,
// select {
//   border-color: #0677c7 !important;
// }
.form-container {
  max-width: 420px !important;
  min-width: 420px !important;
  @media (max-width: 576px) {
    min-width: 320px !important;
  }
}

.form-button {
  text-align: center;
  width: 100%;
  height: 50px;
}

.form-label {
  margin-bottom: 0 !important;
}

.form-spacing {
  padding-bottom: ($spacer * 0.75) !important;
}

.success-container {
  margin-top: -100px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
}

.decline-container {
  margin-top: -100px;
  @media (max-width: 768px) {
    margin-top: 16px;
  }
}

.paragraph-max-width {
  max-width: 680px;
}

.show-hide {
  border-left: 0 !important;
  border-color: #ced4da !important;
  box-shadow: none !important;
  background: none !important;
  color: var(--bs-secondary) !important;
  &:hover {
    color: var(--bs-primary) !important;
  }
}

.toast-container {
  min-height: 240px;
  min-width: auto;
  top: 32px;
  right: 32px;
  zindex: 999;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.error-toast {
  background: #e87070;
}
.subtext {
  color: var(--bs-subtext);
}
